import { type APIMember } from "./membersTypes";

export enum ViewAs {
  GRID = 2,
  LIST = 3,
}

export enum MemberDataLabels {
  EMAIL = "Email",
  CARD_TITLE = "Card Title",
  FIRST_NAME = "First Name",
  LAST_NAME = "Last Name",
  JOB_TITLE = "Job Title",
  COMPANY = "Company",
  LOCATION = "Location",
  SUBTITLE = "Subtitle",
  COVER_PHOTO = "Cover Photo",
  PROFILE_PICTURE = "Profile Picture",
  COMPANY_LOGO = "Company Logo",
  PROFILE_LINK = "Profile Link",
}

type MemberOtherLabels = {
  full_name: "Full Name";
  street_address: "Street Address";
  zip_post_code: "Zip/Post Code";
  city: "City";
  state_province: "State/Province";
  country: "Country";
};

export function getMappedAPIMemberKeys() {
  return new Map<
    keyof APIMember | keyof MemberOtherLabels,
    MemberDataLabels | MemberOtherLabels[keyof MemberOtherLabels]
  >([
    ["email", MemberDataLabels.EMAIL],
    ["full_name", "Full Name"],
    ["first_name", MemberDataLabels.FIRST_NAME],
    ["last_name", MemberDataLabels.LAST_NAME],
    ["designation", MemberDataLabels.JOB_TITLE],
    ["company_name", MemberDataLabels.COMPANY],
    ["bio", MemberDataLabels.SUBTITLE],
    ["company_address", MemberDataLabels.LOCATION],
    ["street_address", "Street Address"],
    ["zip_post_code", "Zip/Post Code"],
    ["city", "City"],
    ["state_province", "State/Province"],
    ["country", "Country"],
  ]);
}

export enum EditMemberSections {
  CONTENT = 0,
  ABOUT = 1,
  COLORS = 2,
  HASHTAG = 3,
  // QR_CODE = 2,
  // LEAD_CAPTURE = 3,
  // EMAIL_SIGNATURE = 4,
  MANAGE_DEVICES = 4,
}

export enum MemberCustomColorsSections {
  SECTION = 0,
  PROFILE = 1,
  PROFILE_BORDER = 2,
  TEXT = 3,
  CONTROL_BUTTONS = 4,
  PROFILE_BUTTONS = 5,
}

export function getEditMemberSectionLabel(value: EditMemberSections): string {
  return value === EditMemberSections.ABOUT
    ? "About"
    : value === EditMemberSections.COLORS
    ? "Colors"
    : value === EditMemberSections.HASHTAG
    ? "HashTag"
    : value === EditMemberSections.MANAGE_DEVICES
    ? "Manage Devices"
    : "Content";
}

export function getEditMemberHashTag(value: EditMemberSections): string {
  return value === EditMemberSections.ABOUT
    ? "#About"
    : value === EditMemberSections.COLORS
    ? "#Colors"
    : value === EditMemberSections.HASHTAG
    ? "#HashTag"
    : value === EditMemberSections.MANAGE_DEVICES
    ? "#ManageDevices"
    : "#Content";
}

export function getEditMemberSection(value: string): EditMemberSections {
  return value === "#About"
    ? EditMemberSections.ABOUT
    : value === "#Colors"
    ? EditMemberSections.COLORS
    : value === "#HashTag"
    ? EditMemberSections.HASHTAG
    : value === "#ManageDevices"
    ? EditMemberSections.MANAGE_DEVICES
    : EditMemberSections.CONTENT;
}
