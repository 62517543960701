import { /*faEnvelope,*/ faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowUpRightFromSquare,
  faHashtag,
  faLaptop,
  faPalette,
  // faMagnet,
  // faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PhonePreview,
  Spinner,
  TabX,
  TabsX,
  tabPanelA11yProps,
} from "../components";
import AppConfigs from "../configs/appConfigs";
import { MemberUpdatedInfo } from "../features/card-customization";
import CardCustomizationHeader from "../features/card-customization/CardCustomizationHeader";
import EditCardContent from "../features/card-customization/EditCardContent";
import EditMemberColors from "../features/card-customization/EditMemberColors";
import EditMemberProfile from "../features/card-customization/EditMemberProfile";
import ManageDevices from "../features/card-customization/ManageDevices";
import SetHashTag from "../features/card-customization/SetHashTag";
import AddDevice from "../features/devices/AddDevice";
import {
  MemberLink,
  getLinkTypes,
  getLinksByMemberId,
  setLinkCategories,
  setLinkTypes,
  setLinks,
  useReadLinkTypesMutation,
  useReadMemberLinksMutation,
} from "../features/links";
import {
  EditMemberSections,
  Member,
  type MemberCustomColors,
  type MemberSettings,
  getMemberColorsSettingsByMemberId,
  getEditMemberHashTag,
  getEditMemberSection,
  getEditMemberSectionLabel,
  getHashTagByUserId,
  getMemberById,
  setMemberHashTags,
  setMemberSettings,
  setMembers,
  useGetAllMembersMutation,
  useReadMemberHashTagsMutation,
} from "../features/members";
import {
  getTemplateByAssigneeId,
  getTemplateSettingsByAssigneeId,
} from "../features/templates";
import useMember from "../hooks/useMember";
import useTemplates from "../hooks/useTemplates";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError, isDeepEqual } from "../utils";
import AddMeeApp from "./addmee-app";

type CardCustomizationRouteParams = {
  id: string;
};

function CardCustomization() {
  const [tabIndex, setTabIndex] = useState<EditMemberSections>(
    getEditMemberSection(window.location.hash)
  );
  const [appMember, setAppMember] = useState<Member>();
  const [appMemberLinks, setAppMemberLinks] = useState<MemberLink[]>();
  const [appSettings, setAppSettings] = useState<MemberSettings>();
  const [aboutChanged, setAboutChanged] = useState<boolean>(false);
  const [colorsChanged, setColorsChanged] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { id } = useParams<CardCustomizationRouteParams>();

  const { devices: thisMemberDevices } = useMember(Number(id));
  const { isLoading: isTemplatesLoading } = useTemplates();

  const thisMember = useAppSelector((state: RootState) =>
    getMemberById(state, Number(id))
  );

  const thisMemberColorsSetting = useAppSelector((state: RootState) =>
    getMemberColorsSettingsByMemberId(state, Number(id))
  );

  const thisMemberTemplate = useAppSelector((state: RootState) =>
    getTemplateByAssigneeId(state, Number(id))
  );

  const thisMemberTemplateSettings = useAppSelector((state: RootState) =>
    getTemplateSettingsByAssigneeId(state, Number(id))
  );

  const [getAllMembers] = useGetAllMembersMutation();

  useEffect(() => {
    if (!thisMember || !thisMemberColorsSetting) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { members, settings },
          } = await getAllMembers().unwrap();
          if (success) {
            dispatch(setMembers(members));
            dispatch(setMemberSettings(settings));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [thisMember, thisMemberColorsSetting, getAllMembers, dispatch]);

  useEffect(() => {
    if (thisMember && thisMemberColorsSetting) {
      setAppMember({
        ...thisMember,
        profileImage:
          thisMemberTemplate?.profileImage || thisMember.profileImage,
        companyLogo: thisMemberTemplate?.companyLogo || thisMember.companyLogo,
        profileBanner:
          thisMemberTemplate?.profileBanner || thisMember.profileBanner,
        companyAddress:
          thisMemberTemplate?.companyAddress || thisMember.companyAddress,
        companyName: thisMemberTemplate?.companyName || thisMember.companyName,
        subtitle: thisMemberTemplate?.subtitle || thisMember.subtitle,
        connectButton: thisMemberTemplate?.isControlButtonsLocked
          ? thisMemberTemplate?.connectButton
          : thisMember.connectButton,
        saveContactButton: thisMemberTemplate?.isControlButtonsLocked
          ? thisMemberTemplate?.saveContactButton
          : thisMember.saveContactButton,
        captureLead: thisMemberTemplate?.isProfileOpensLocked
          ? thisMemberTemplate?.captureLead
          : thisMember.captureLead,
        openDirect: thisMemberTemplate?.isProfileOpensLocked
          ? thisMemberTemplate?.openDirect
          : thisMember.openDirect,
      });

      if (
        thisMemberTemplate?.isColorsCustomLocked &&
        thisMemberTemplateSettings
      ) {
        setAppSettings({
          colors: {
            ...thisMemberColorsSetting,
            ...thisMemberTemplateSettings?.colors,
          },
        });
      } else {
        setAppSettings({
          colors: thisMemberColorsSetting,
        });
      }
    }
  }, [
    thisMember,
    thisMemberColorsSetting,
    thisMemberTemplate,
    thisMemberTemplateSettings,
  ]);

  const thisMemberLinks = useAppSelector((state: RootState) =>
    getLinksByMemberId(state, Number(id))
  );

  const [readMemberLinks] = useReadMemberLinksMutation();

  useEffect(() => {
    if (!thisMemberLinks) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { links: allMemberLinks },
          } = await readMemberLinks().unwrap();
          if (success) {
            dispatch(setLinks(allMemberLinks));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    } else {
      setAppMemberLinks(thisMemberLinks);
    }
  }, [thisMemberLinks, readMemberLinks, dispatch]);

  const linkTypes = useAppSelector(getLinkTypes);

  const [readLinkTypes] = useReadLinkTypesMutation();

  useEffect(() => {
    if (linkTypes.length === 0) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { linkCategories, linkTypes: allLinkTypes },
          } = await readLinkTypes().unwrap();
          if (success) {
            dispatch(setLinkCategories(linkCategories));
            dispatch(setLinkTypes(allLinkTypes));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [linkTypes, readLinkTypes, dispatch]);

  const previewAboutChange = useCallback(
    (updatedMemberInfo: MemberUpdatedInfo) => {
      setAppMember((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            profileImage:
              updatedMemberInfo.profileImageSrc ?? prevState.profileImage,
            profileBanner:
              updatedMemberInfo.profileBannerSrc ?? prevState.profileBanner,
            companyLogo:
              updatedMemberInfo.companyLogoSrc ?? prevState.companyLogo,
            fullName: updatedMemberInfo.fullName,
            designation: updatedMemberInfo.designation,
            companyName: updatedMemberInfo.companyName,
            companyAddress: updatedMemberInfo.companyAddress,
            subtitle: updatedMemberInfo.subtitle,
          };
        }
      });
    },
    []
  );

  const previewColorsChange = useCallback(
    (updatedMemberColors: MemberCustomColors) => {
      setAppSettings((prevState) => {
        if (prevState) {
          return {
            colors: {
              ...updatedMemberColors,
              id: prevState.colors.id,
              userId: prevState.colors.userId,
            },
          };
        }
      });
    },
    []
  );

  const previewContentChange = useCallback(
    (updatedMemberLinks: MemberLink[]) => {
      setAppMemberLinks(updatedMemberLinks);
    },
    []
  );

  function isAboutChanged(): boolean {
    if (appMember && appSettings) {
      let isMemberDataChanged = false;

      if (thisMemberTemplate) {
        isMemberDataChanged = !isDeepEqual(appMember, {
          ...thisMember,
          profileImage:
            thisMemberTemplate.profileImage || thisMember?.profileImage,
          profileBanner:
            thisMemberTemplate.profileBanner || thisMember?.profileBanner,
          companyLogo:
            thisMemberTemplate.companyLogo || thisMember?.companyLogo,
          companyName:
            thisMemberTemplate.companyName || thisMember?.companyName,
          companyAddress:
            thisMemberTemplate.companyAddress || thisMember?.companyAddress,
          subtitle: thisMemberTemplate.subtitle || thisMember?.subtitle,
          connectButton: thisMemberTemplate.isControlButtonsLocked
            ? thisMemberTemplate.connectButton
            : thisMember?.connectButton,
          saveContactButton: thisMemberTemplate.isControlButtonsLocked
            ? thisMemberTemplate.saveContactButton
            : thisMember?.saveContactButton,
          captureLead: thisMemberTemplate.isProfileOpensLocked
            ? thisMemberTemplate.captureLead
            : thisMember?.captureLead,
          openDirect: thisMemberTemplate.isProfileOpensLocked
            ? thisMemberTemplate.openDirect
            : thisMember?.openDirect,
        });
      } else {
        isMemberDataChanged = !isDeepEqual(appMember, thisMember);
      }

      return isMemberDataChanged;
    }

    return false;
  }

  function isColorsChanged(): boolean {
    if (appMember && appSettings) {
      let isSettingsChanged = false;

      if (thisMemberTemplate) {
        if (thisMemberTemplate.isColorsCustomLocked) {
          isSettingsChanged = !isDeepEqual(appSettings?.colors, {
            ...thisMemberColorsSetting,
            ...thisMemberTemplateSettings?.colors,
          });
        }
      } else {
        isSettingsChanged = !isDeepEqual(
          appSettings?.colors,
          thisMemberColorsSetting
        );
      }

      return isSettingsChanged;
    }

    return false;
  }

  function handleTabXChange(
    _event: SyntheticEvent<Element, Event>,
    value: EditMemberSections
  ): void {
    if (tabIndex === EditMemberSections.ABOUT && isAboutChanged()) {
      setAboutChanged(true);
    } else if (tabIndex === EditMemberSections.COLORS && isColorsChanged()) {
      setColorsChanged(true);
    } else {
      setAboutChanged(false);
      setColorsChanged(false);
      setTabIndex(value);
      window.location.hash = getEditMemberHashTag(value);
    }
  }

  useEffect(() => {
    const handleHashChange = () => {
      setTabIndex(getEditMemberSection(window.location.hash));
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const thisMemberHashTag = useAppSelector((state: RootState) =>
    getHashTagByUserId(state, Number(id))
  );

  const [readMemberHashTags] = useReadMemberHashTagsMutation();

  useEffect(() => {
    if (thisMemberHashTag === undefined) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { allHashTags },
          } = await readMemberHashTags().unwrap();
          if (success) {
            dispatch(setMemberHashTags(allHashTags));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [thisMemberHashTag, readMemberHashTags, dispatch]);

  if (
    !thisMember ||
    !thisMemberColorsSetting ||
    !thisMemberLinks ||
    isTemplatesLoading
  ) {
    return <Spinner fullScreen />;
  }

  return (
    <div className="relative p-8 pt-[7.25rem]">
      <CardCustomizationHeader
        member={thisMember}
        className="absolute inset-0 bottom-auto"
      />
      <div className="flex flex-col gap-8 rounded-2xl bg-white px-7 py-8">
        <TabsX
          name={`Edit ${thisMember.firstName}'s Profile`}
          value={tabIndex}
          onChange={handleTabXChange}
        >
          <TabX
            icon={<GridViewOutlinedIcon />}
            label={getEditMemberSectionLabel(EditMemberSections.CONTENT)}
            index={EditMemberSections.CONTENT}
          />
          <TabX
            icon={<FontAwesomeIcon icon={faUser} />}
            label={getEditMemberSectionLabel(EditMemberSections.ABOUT)}
            index={EditMemberSections.ABOUT}
          />
          <TabX
            icon={<FontAwesomeIcon icon={faPalette} />}
            label={getEditMemberSectionLabel(EditMemberSections.COLORS)}
            index={EditMemberSections.COLORS}
          />
          <TabX
            icon={<FontAwesomeIcon icon={faHashtag} />}
            label={getEditMemberSectionLabel(EditMemberSections.HASHTAG)}
            index={EditMemberSections.HASHTAG}
          />
          {/* <TabX
            icon={<FontAwesomeIcon icon={faQrcode} />}
            label={getEditMemberSectionLabel(Sections.QR_CODE)}
            index={Sections.QR_CODE}
          />
          <TabX
            icon={<FontAwesomeIcon icon={faMagnet} />}
            label={getEditMemberSectionLabel(Sections.LEAD_CAPTURE)}
            index={Sections.LEAD_CAPTURE}
          />
          <TabX
            icon={<FontAwesomeIcon icon={faEnvelope} />}
            label={getEditMemberSectionLabel(Sections.EMAIL_SIGNATURE)}
            index={Sections.EMAIL_SIGNATURE}
          /> */}
          <TabX
            icon={<FontAwesomeIcon icon={faLaptop} />}
            label={getEditMemberSectionLabel(EditMemberSections.MANAGE_DEVICES)}
            index={EditMemberSections.MANAGE_DEVICES}
          />
        </TabsX>
        <div className="flex gap-7">
          <div className="grow">
            {tabIndex === EditMemberSections.CONTENT && (
              <EditCardContent
                member={thisMember}
                memberLinks={thisMemberLinks}
                template={thisMemberTemplate || undefined}
                onChange={previewContentChange}
                {...tabPanelA11yProps(EditMemberSections.CONTENT)}
              />
            )}
            {tabIndex === EditMemberSections.ABOUT && (
              <EditMemberProfile
                member={thisMember}
                template={thisMemberTemplate || undefined}
                onChange={previewAboutChange}
                changed={aboutChanged}
                onChangesSaved={() => setAboutChanged(false)}
                onChangesDiscarded={() => setAboutChanged(false)}
                {...tabPanelA11yProps(EditMemberSections.ABOUT)}
              />
            )}
            {tabIndex === EditMemberSections.COLORS && (
              <EditMemberColors
                member={thisMember}
                memberColors={thisMemberColorsSetting}
                template={thisMemberTemplate || undefined}
                templateColors={thisMemberTemplateSettings?.colors || undefined}
                onChange={previewColorsChange}
                changed={colorsChanged}
                onChangesSaved={() => setColorsChanged(false)}
                onChangesDiscarded={() => setColorsChanged(false)}
                {...tabPanelA11yProps(EditMemberSections.COLORS)}
              />
            )}
            {tabIndex === EditMemberSections.HASHTAG && (
              <SetHashTag
                memberIds={[thisMember.id]}
                hashTag={thisMemberHashTag}
                {...tabPanelA11yProps(EditMemberSections.HASHTAG)}
              />
            )}
            {/* {tabIndex === Sections.QR_CODE && (
              <div {...tabPanelA11yProps(Sections.QR_CODE)}>
                <h4 className='text-xl font-semibold text-black'>QR Code</h4>
              </div>
            )}
            {tabIndex === Sections.LEAD_CAPTURE && (
              <div {...tabPanelA11yProps(Sections.LEAD_CAPTURE)}>
                <h4 className='text-xl font-semibold text-black'>Lead Capture</h4>
              </div>
            )}
            {tabIndex === Sections.EMAIL_SIGNATURE && (
              <div {...tabPanelA11yProps(Sections.EMAIL_SIGNATURE)}>
                <h4 className='text-xl font-semibold text-black'>Email Signatue</h4>
              </div>
            )} */}
            {tabIndex === EditMemberSections.MANAGE_DEVICES && (
              <ManageDevices
                member={thisMember}
                devices={thisMemberDevices}
                {...tabPanelA11yProps(EditMemberSections.MANAGE_DEVICES)}
              />
            )}
          </div>
          {tabIndex !== EditMemberSections.MANAGE_DEVICES ||
          (thisMemberDevices && thisMemberDevices.length === 0) ? (
            <div className="flex flex-col gap-6 rounded-3xl border border-gray-200 px-8 py-6">
              <div className="flex items-center justify-between gap-6">
                <h4 className="text-lg font-semibold text-black">Preview</h4>
                <a
                  href={`${AppConfigs.CARD_BASE_URL}/${thisMember.username}`}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex items-center gap-3 text-sm text-black"
                >
                  View card <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>
              </div>
              <PhonePreview
                mutableData={{
                  id: thisMember?.id,
                  captureLead: thisMember?.captureLead,
                  openDirect: thisMember?.openDirect,
                }}
              >
                {appMember && appMemberLinks && appSettings && (
                  <AddMeeApp
                    member={appMember}
                    links={appMemberLinks}
                    settings={appSettings}
                  />
                )}
              </PhonePreview>
            </div>
          ) : (
            <AddDevice member={thisMember} />
          )}
        </div>
      </div>
    </div>
  );
}

export default CardCustomization;
