import { faBuilding, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsRotate,
  faBroom,
  faEye,
  faFileCsv,
  faHandshakeAlt,
  faHandshakeAltSlash,
  faHashtag,
  faImagePortrait,
  faLink,
  faLinkSlash,
  faLock,
  faPanorama,
  faPlus,
  faShieldHalved,
  faSpinner,
  faUnlock,
  faUserSlash,
  faUsers,
  faUsersSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, FormControlLabel } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AzureLogoGray } from "../assets/images/brands";
import {
  AlertDialog,
  ConfirmationDialog,
  DialogX,
  DropdownItemX,
  DropdownX,
  ImageCropper,
  MemberCard,
  MembersListControls,
  MembersListView,
  SelectionBar,
  Spinner,
  SyncADConfigsDialog,
} from "../components";
import AppConfigs from "../configs/appConfigs";
import {
  AddMembersBySections,
  getAddMembersByHashTag,
} from "../features/add-members/addMembersEnums";
import type { LinkToSync } from "../features/add-members/addMembersTypes";
import type { MembersUpdateImage } from "../features/card-customization/cardCustomizationTypes";
import SetHashTag from "../features/card-customization/SetHashTag";
import {
  CreateBulkLinkResponse,
  CreateNewLinkResponse,
  LinkType,
  NewMemberLink,
  addNewLinks,
  removeLinksByMemberIds,
  setLinks,
  useCreateBulkLinkMutation,
  useCreateNewLinkMutation,
  useReadMemberLinksMutation,
} from "../features/links";
import SelectLinkType from "../features/links/SelectLinkType";
import AddMemberLink from "../features/links/AddMemberLink";
import {
  DropdownMenuItem,
  Member,
  MemberDataLabels,
  MemberHashTag,
  SelectedMember,
  ViewAs,
  editMembers,
  getMemberHashTags,
  removeMemberHashTags,
  removeMemberSettingsByMemberIds,
  removeMembers,
  setMemberHashTags,
  useDeleteMemberHashTagsMutation,
  useDeleteMembersMutation,
  useLockMembersMutation,
  useReadMemberHashTagsMutation,
  useSendResetPasswordInviteMutation,
  useSetCompanyLogoMutation,
  useSetProfileBannerMutation,
  useSetProfilePictureMutation,
  useSwitchConnectButtonMutation,
  useSwitchDirectOpenMutation,
  useSwitchMembersVisibilityMutation,
  useSwitchSaveContactButtonMutation,
  useUnlockMembersMutation,
} from "../features/members";
import {
  defaultSyncADConfigs,
  Platforms,
} from "../features/integrations/integrationsEnums";
import type { SyncADConfigsFormData } from "../features/integrations/integrationsTypes";
import { Template, removeTemplateAssignees } from "../features/templates";
import { getCurrentUser, UserRole } from "../features/users";
import useIntegration from "../hooks/useIntegration";
import useIntegrations from "../hooks/useIntegrations";
import useMembers from "../hooks/useMembers";
import useTemplates from "../hooks/useTemplates";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError, validateImage } from "../utils";
import {
  editSyncedMembers,
  getIntegrationsByPlatformName,
  getPlatformByName,
  useUpdateSyncedMembersMutation,
} from "../features/integrations";

function Members() {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedOnes, setSelectedOnes] = useState<GridRowSelectionModel>([]);
  const [view, setView] = useState<ViewAs>(ViewAs.GRID);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);
  const [addLinkDialogOpen, setAddLinkDialogOpen] = useState<boolean>(false);
  const [linkTypeToAdd, setLinkTypeToAdd] = useState<LinkType>();
  const [idsToDelete, setIdsToDelete] = useState<number[]>([]);
  const [deleteMembersDialogShow, setDeleteMembersDialogShow] =
    useState<boolean>(false);
  const [deleteOwnerAlertShow, setDeleteOwnerAlertShow] =
    useState<boolean>(false);
  const [deleteAdminAlertShow, setDeleteAdminAlertShow] =
    useState<boolean>(false);
  const [idsToClearHashTags, setIdsToClearHashTags] =
    useState<Array<MemberHashTag["id"]>>();
  const [clearMemberHashTagsDialogShow, setClearMemberHashTagsDialogShow] =
    useState<boolean>(false);
  const [userIdsToAddHashTags, setUserIdsToAddHashTags] =
    useState<Array<MemberHashTag["userId"]>>();
  const [addMemberHashTagsDialogShow, setAddMemberHashTagsDialogShow] =
    useState<boolean>(false);
  const [imageCropperDialogOpen, setImageCropperDialogOpen] =
    useState<boolean>(false);
  const [membersUpdateImage, setMembersUpdateImage] =
    useState<MembersUpdateImage>();
  const [limitedMappingAlertOpen, setLimitedMappingAlertOpen] =
    useState<boolean>(false);
  const [idsToChangeADSyncConfigs, setIdsToChangeADSyncConfigs] = useState<
    Array<Member["id"]>
  >([]);
  const [syncADConfigsDialogOpen, setSyncADConfigsDialogOpen] =
    useState<boolean>(false);
  const [thisSyncADConfigs, setThisSyncADConfigs] =
    useState<SyncADConfigsFormData>(defaultSyncADConfigs);
  const [overwriteConfirmOpen, setOverwriteConfirmOpen] =
    useState<boolean>(false);

  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(getCurrentUser);

  const { members, isLoading: isMembersLoading } = useMembers();
  const {
    templates,
    templateLinks,
    isLoading: isReadTemplatesPending,
  } = useTemplates();
  const { syncedMembers, isLoading: isIntegrationsLoading } = useIntegrations();
  const { openOAuthPopup, isLoading: isIntegrationLoading } = useIntegration({
    platform: Platforms.ACTIVE_DIRECTORY,
    callback: () =>
      navigateTo(
        "/members/new" +
          getAddMembersByHashTag(AddMembersBySections.ACTIVE_DIRECTORY)
      ),
  });

  const activeDirectoryPlatform = useAppSelector((state: RootState) =>
    getPlatformByName(state, Platforms.ACTIVE_DIRECTORY)
  );
  const activeDirectoryIntegration = useAppSelector(
    (state: RootState) =>
      getIntegrationsByPlatformName(state, Platforms.ACTIVE_DIRECTORY)?.[0]
  );

  const [readMemberLinks] = useReadMemberLinksMutation();

  useEffect(() => {
    if (!members) {
      (async () => {
        try {
          const {
            success: isReadMemberLinksSuccess,
            message: readMemberLinksMessage,
            data: { links },
          } = await readMemberLinks().unwrap();
          if (isReadMemberLinksSuccess) {
            dispatch(setLinks(links));
          } else {
            toast.error(readMemberLinksMessage);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    } else if (searchKeyword.length >= 3) {
      setFilteredMembers(
        members.filter(
          (member) =>
            member.fullName
              ?.toLowerCase()
              .includes(searchKeyword.toLowerCase()) ||
            member.username
              ?.toLowerCase()
              .includes(searchKeyword.toLowerCase()) ||
            member.email?.toLowerCase().includes(searchKeyword.toLowerCase())
        )
      );
    } else {
      setFilteredMembers(members);
    }

    return () => {
      setFilteredMembers([]);
    };
  }, [members, searchKeyword, dispatch, readMemberLinks]);

  useEffect(() => {
    setSelectAll(selectedOnes.length === filteredMembers.length);
  }, [selectedOnes.length, filteredMembers.length]);

  function handleSelectAllChange(
    _e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>,
    checked: boolean
  ) {
    setSelectAll(checked);
    if (checked && filteredMembers.length) {
      setSelectedOnes([...filteredMembers.map((m) => m.id)]);
    } else {
      setSelectedOnes([]);
    }
  }

  function handleSelectedChange(id: number, selected: boolean) {
    if (!selected) {
      setSelectedOnes([...selectedOnes.filter((s) => s !== id)]);
    } else {
      setSelectedOnes([id, ...selectedOnes]);
    }
  }

  function getSelectedMembers(
    theMembers: Member[],
    selectedIds: Array<Member["id"]>
  ): SelectedMember[] {
    return theMembers
      .filter((m) => selectedIds.some((s) => s === m.id))
      .map((m) => ({
        id: m.id,
        fullName: m.fullName,
        profileImage: m.profileImage,
        locked: m.locked,
        isPublic: m.isPublic,
        connectButton: m.connectButton,
        saveContactButton: m.saveContactButton,
        openDirect: m.openDirect || false,
        assignedTemplate: getTemplateByMemberId(m.id),
        isSyncedWithAD: syncedMembers?.some((sm) => sm.memberId === m.id),
      }));
  }

  function getActionsOnSelected(): DropdownMenuItem[] {
    const selectedMembers = getSelectedMembers(
      filteredMembers,
      selectedOnes.map((s) => Number(s))
    );

    if (
      selectedMembers.every(
        (s) =>
          s.assignedTemplate?.profileBanner &&
          s.assignedTemplate?.profileImage &&
          s.assignedTemplate?.companyLogo
      )
    ) {
      return actionsOnSelected.filter((a) => a.name !== "Images");
    }

    return actionsOnSelected;
  }

  const [switchDirectOpen] = useSwitchDirectOpenMutation();

  async function handleDirectOpenChange(ids: Array<Member["id"]>, on: boolean) {
    try {
      const {
        success,
        message,
        data: { members: switchedMembers },
      } = await switchDirectOpen({
        ids: ids.filter(
          (id) => !getTemplateByMemberId(id)?.isProfileOpensLocked
        ),
        on,
      }).unwrap();
      if (success) {
        dispatch(editMembers(switchedMembers));
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [lockMembers] = useLockMembersMutation();
  const [unlockMembers] = useUnlockMembersMutation();

  async function handleLockMembersChange(
    ids: Array<Member["id"]>,
    locked: boolean
  ) {
    try {
      const response = locked
        ? await lockMembers(ids).unwrap()
        : await unlockMembers(ids).unwrap();
      if (response.success) {
        dispatch(editMembers(response.data.members));
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [switchMembersVisibility] = useSwitchMembersVisibilityMutation();

  async function handleSwitchMembersVisibilityChange(
    ids: Array<Member["id"]>,
    isPublic: boolean
  ) {
    try {
      const {
        success,
        message,
        data: { members: switchedMembers },
      } = await switchMembersVisibility({
        ids,
        isPublic,
      }).unwrap();
      if (success) {
        dispatch(editMembers(switchedMembers));
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [switchConnectButton] = useSwitchConnectButtonMutation();

  async function handleConnectButtonChange(
    ids: Array<Member["id"]>,
    show: boolean
  ) {
    try {
      const {
        success,
        message,
        data: { members: switchedMembers },
      } = await switchConnectButton({
        ids: ids.filter(
          (id) => !getTemplateByMemberId(id)?.isControlButtonsLocked
        ),
        show,
      }).unwrap();
      if (success) {
        dispatch(editMembers(switchedMembers));
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [switchSaveContactButton] = useSwitchSaveContactButtonMutation();

  async function handleSaveContactButtonChange(
    ids: Array<Member["id"]>,
    show: boolean
  ) {
    try {
      const {
        success,
        message,
        data: { members: switchedMembers },
      } = await switchSaveContactButton({
        ids: ids.filter(
          (id) => !getTemplateByMemberId(id)?.isControlButtonsLocked
        ),
        show,
      }).unwrap();
      if (success) {
        dispatch(editMembers(switchedMembers));
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [sendResetPasswordInvite] = useSendResetPasswordInviteMutation();

  async function handleResetPasswordSendMailClick(ids: Array<Member["id"]>) {
    try {
      const {
        success,
        message,
        // data: { members },
      } = await sendResetPasswordInvite(ids).unwrap();
      if (success) {
        // dispatch(editMembers(members));
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  function getAspectRatio(type: MemberDataLabels): number {
    return type === MemberDataLabels.COVER_PHOTO ? 30 / 11 : 1;
  }

  function getCropShape(type: MemberDataLabels): "rect" | "round" {
    return type === MemberDataLabels.COVER_PHOTO ? "rect" : "round";
  }

  async function handleImageUpload(
    ids: Array<Member["id"]>,
    type:
      | MemberDataLabels.PROFILE_PICTURE
      | MemberDataLabels.COVER_PHOTO
      | MemberDataLabels.COMPANY_LOGO,
    files?: FileList | null
  ) {
    const file = files?.item(0);
    if (file) {
      const { success, message } = validateImage({
        file,
        accept: AppConfigs.getAcceptedFileTypes(type),
        // maxSize: AppConfigs.getMaximumFileSize(type),
      });

      if (!success) {
        toast.error(message);
        return;
      }

      setMembersUpdateImage({ ids, type, file });
      setImageCropperDialogOpen(true);
    }
  }

  function handleImageCropperCancel(): void {
    setImageCropperDialogOpen(false);
    setMembersUpdateImage(undefined);
  }

  const [setProfilePicture, { isLoading: isSetProfilePictureLoading }] =
    useSetProfilePictureMutation();
  const [setProfileBanner, { isLoading: isSetProfileBannerLoading }] =
    useSetProfileBannerMutation();
  const [setCompanyLogo, { isLoading: isSetCompanyLogoLoading }] =
    useSetCompanyLogoMutation();
  async function handleCroppedImage(file: File): Promise<void> {
    if (membersUpdateImage) {
      const { ids, type } = membersUpdateImage;
      const response =
        type === MemberDataLabels.PROFILE_PICTURE
          ? await setProfilePicture({ ids, file }).unwrap()
          : type === MemberDataLabels.COVER_PHOTO
          ? await setProfileBanner({ ids, file }).unwrap()
          : type === MemberDataLabels.COMPANY_LOGO
          ? await setCompanyLogo({ ids, file }).unwrap()
          : undefined;
      if (response?.success) {
        dispatch(editMembers(response.data.members));
        handleImageCropperCancel();
      } else if (response) {
        toast.error(response.message);
      }
    }
  }

  const membersChildren: DropdownMenuItem[] = [
    {
      key: "ActivateOneShare",
      name: "Activate OneShare",
      icon: <FontAwesomeIcon icon={faHandshakeAlt} />,
      onClick: (ids) => handleDirectOpenChange(ids, true),
    },
    {
      key: "DeactivateOneShare",
      name: "Deactivate OneShare",
      icon: <FontAwesomeIcon icon={faHandshakeAltSlash} />,
      onClick: (ids) => handleDirectOpenChange(ids, false),
    },
    {
      key: "Lock",
      name: "Lock",
      icon: <FontAwesomeIcon icon={faLock} />,
      onClick: (ids) => handleLockMembersChange(ids, true),
    },
    {
      key: "Unlock",
      name: "Unlock",
      icon: <FontAwesomeIcon icon={faUnlock} />,
      onClick: (ids) => handleLockMembersChange(ids, false),
    },
    {
      key: "Private",
      name: "Set as Private Profiles",
      icon: <FontAwesomeIcon icon={faShieldHalved} />,
      onClick: (ids) => handleSwitchMembersVisibilityChange(ids, false),
    },
    {
      key: "Public",
      name: "Set as Public Profiles",
      icon: <FontAwesomeIcon icon={faEye} />,
      onClick: (ids) => handleSwitchMembersVisibilityChange(ids, true),
    },
    {
      key: "ShowConnectButton",
      name: "Show Connect Button",
      icon: <FontAwesomeIcon icon={faUsers} />,
      onClick: (ids) => handleConnectButtonChange(ids, true),
    },
    {
      key: "HideConnectButton",
      name: "Hide Connect Button",
      icon: <FontAwesomeIcon icon={faUsersSlash} />,
      onClick: (ids) => handleConnectButtonChange(ids, false),
    },
    {
      key: "ShowSaveContactButton",
      name: "Show Save Contact Button",
      icon: <FontAwesomeIcon icon={faLink} />,
      onClick: (ids) => handleSaveContactButtonChange(ids, true),
    },
    {
      key: "HideSaveContactButton",
      name: "Hide Save Contact Button",
      icon: <FontAwesomeIcon icon={faLinkSlash} />,
      onClick: (ids) => handleSaveContactButtonChange(ids, false),
    },
    {
      name: "Reset Password & Send Mail",
      icon: <FontAwesomeIcon icon={faArrowsRotate} />,
      onClick: handleResetPasswordSendMailClick,
    },
  ];

  const divider: DropdownMenuItem[] = [
    {
      name: "",
      type: "divider",
    },
  ];

  const imagesChildren: DropdownMenuItem[] = [
    {
      key: "SetProfilePicture",
      name: `Set ${MemberDataLabels.PROFILE_PICTURE}`,
      icon: <FontAwesomeIcon icon={faImagePortrait} />,
      onClick: async (ids: number[], files?: FileList | null) =>
        handleImageUpload(
          ids.filter((id) => !getTemplateByMemberId(id)?.profileImage),
          MemberDataLabels.PROFILE_PICTURE,
          files
        ),
      type: "uploader",
      acceptedFileTypes: AppConfigs.getAcceptedFileTypes(
        MemberDataLabels.PROFILE_PICTURE
      ),
    },
    {
      key: "SetCoverPhoto",
      name: `Set ${MemberDataLabels.COVER_PHOTO}`,
      icon: <FontAwesomeIcon icon={faPanorama} />,
      onClick: async (ids: number[], files?: FileList | null) =>
        handleImageUpload(
          ids.filter((id) => !getTemplateByMemberId(id)?.profileBanner),
          MemberDataLabels.COVER_PHOTO,
          files
        ),
      type: "uploader",
      acceptedFileTypes: AppConfigs.getAcceptedFileTypes(
        MemberDataLabels.COVER_PHOTO
      ),
    },
    {
      key: "SetCompanyLogo",
      name: `Set ${MemberDataLabels.COMPANY_LOGO}`,
      icon: <FontAwesomeIcon icon={faBuilding} />,
      onClick: async (ids: number[], files?: FileList | null) =>
        handleImageUpload(
          ids.filter((id) => !getTemplateByMemberId(id)?.companyLogo),
          MemberDataLabels.COMPANY_LOGO,
          files
        ),
      type: "uploader",
      acceptedFileTypes: AppConfigs.getAcceptedFileTypes(
        MemberDataLabels.COMPANY_LOGO
      ),
    },
  ];

  const integrationsChildren: DropdownMenuItem[] = [
    {
      key: "ADSyncSettings",
      name: "Active Directory Settings",
      icon: <AzureLogoGray className="h-3.5 group-hover:fill-primary" />,
      onClick: (ids) => handleADSyncSettingsChange(ids),
    },
  ];

  const membersChildrenMore: DropdownMenuItem[] = [
    {
      name: "Delete Member",
      icon: <FontAwesomeIcon icon={faUserSlash} />,
      onClick: (ids: number[]) => {
        setIdsToDelete(ids);
        if (
          currentUser?.role === UserRole.BUSINESS_OWNER &&
          members?.some(
            (m) => m.role === UserRole.BUSINESS_OWNER && ids.includes(m.id)
          )
        ) {
          setDeleteOwnerAlertShow(true);
        } else if (
          currentUser?.role === UserRole.BUSINESS_ADMIN &&
          members?.some(
            (m) =>
              (m.role === UserRole.BUSINESS_OWNER ||
                m.role === UserRole.BUSINESS_ADMIN) &&
              ids.includes(m.id)
          )
        ) {
          setDeleteAdminAlertShow(true);
        } else {
          setDeleteMembersDialogShow(true);
        }
      },
      classes: {
        icon: "!text-error-400 group-hover:!text-error-500",
        name: "!text-error-400 group-hover:!text-error-500",
      },
    },
  ];

  const actionsOnSelected: DropdownMenuItem[] = [
    {
      name: "Content",
      children: [
        {
          name: "Add Links & Contact Info",
          icon: <FontAwesomeIcon icon={faPlus} />,
          onClick: async (ids: number[]) => {
            setLinkTypeToAdd(undefined);
            setAddLinkDialogOpen(true);
          },
        },
      ],
    },
    {
      name: "Member",
      children: [...membersChildren, ...divider, ...membersChildrenMore],
    },
    {
      name: "Images",
      children: imagesChildren,
    },
    ...(activeDirectoryIntegration
      ? [
          {
            name: "Integrations",
            children: integrationsChildren,
          },
        ]
      : []),
    {
      name: "HashTag",
      children: [
        {
          key: "AddHashTag",
          name: "Add HashTag",
          icon: <FontAwesomeIcon icon={faHashtag} />,
          onClick: showAddMemberHashTagsDialog,
        },
        {
          key: "ClearHashTags",
          name: "Clear HashTags",
          icon: <FontAwesomeIcon icon={faBroom} />,
          onClick: showClearMemberHashTagsDialog,
        },
      ],
    },
  ];

  const [createNewLink] = useCreateNewLinkMutation();
  const [createBulkLink] = useCreateBulkLinkMutation();

  async function addToLinks(newLink: NewMemberLink) {
    try {
      const response: CreateNewLinkResponse | CreateBulkLinkResponse =
        newLink.isGlobal
          ? await createNewLink({
              userId: Number(selectedOnes[0]),
              ...newLink,
            }).unwrap()
          : await createBulkLink({
              memberIds: selectedOnes.map((so) => Number(so)),
              link: newLink,
            }).unwrap();
      if (response.success) {
        dispatch(addNewLinks(response.data.links));
        setAddLinkDialogOpen(false);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [deleteMembers, { isLoading: isDeleteMembersPending }] =
    useDeleteMembersMutation();

  async function handleDeleteMembersConfirm() {
    if (idsToDelete.length) {
      try {
        const { success, message } = await deleteMembers({
          ids: idsToDelete,
          reason: "Deleted by Admin.",
        }).unwrap();
        if (success) {
          dispatch(removeMembers(idsToDelete));
          dispatch(removeLinksByMemberIds(idsToDelete));
          dispatch(removeMemberSettingsByMemberIds(idsToDelete));
          dispatch(removeTemplateAssignees(idsToDelete));
          setSelectAll(false);
          setSelectedOnes([]);
          toast.success(message);
        } else {
          toast.error(message);
        }
      } catch (error) {
        handleFetchError(error as FetchError);
      }

      setIdsToDelete([]);
    }

    setDeleteMembersDialogShow(false);
  }

  function handleDeleteMembersCancel(): void {
    setDeleteMembersDialogShow(false);
    setIdsToDelete([]);
  }

  const getTemplateByMemberId = (memberId: number): Template | undefined => {
    return templates?.find((t) => t.assigneeIds.includes(memberId));
  };

  const memberHashTags = useAppSelector(getMemberHashTags);

  const [readMemberHashTags] = useReadMemberHashTagsMutation();

  useEffect(() => {
    if (memberHashTags === undefined) {
      (async () => {
        try {
          const {
            success,
            message,
            data: { allHashTags },
          } = await readMemberHashTags().unwrap();
          if (success) {
            dispatch(setMemberHashTags(allHashTags));
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [memberHashTags, readMemberHashTags, dispatch]);

  function showClearMemberHashTagsDialog(userIds: Array<Member["id"]>) {
    setIdsToClearHashTags(
      memberHashTags
        ?.filter((mht) => userIds.includes(mht.userId))
        .map((ht) => ht.id)
    );
    setClearMemberHashTagsDialogShow(true);
  }

  const [deleteMemberHashTags, { isLoading: isDeleteMemberHashTagsPending }] =
    useDeleteMemberHashTagsMutation();

  async function handleClearMemberHashTagsConfirm(): Promise<void> {
    try {
      if (idsToClearHashTags?.length) {
        const {
          success,
          message,
          data: { deletedHashTagIds },
        } = await deleteMemberHashTags(idsToClearHashTags).unwrap();
        if (success) {
          dispatch(removeMemberHashTags(deletedHashTagIds));
          setSelectedOnes([]);
          setIdsToClearHashTags(undefined);
          setClearMemberHashTagsDialogShow(false);
        } else {
          toast.error(message);
        }
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  function showAddMemberHashTagsDialog(userIds: Array<Member["id"]>) {
    setUserIdsToAddHashTags(userIds);
    setAddMemberHashTagsDialogShow(true);
  }

  function handleAddMemberHashTagsDialogClose() {
    setSelectedOnes([]);
    setUserIdsToAddHashTags(undefined);
    setAddMemberHashTagsDialogShow(false);
  }

  function handleADSyncSettingsChange(ids: Array<Member["id"]>) {
    setIdsToChangeADSyncConfigs(
      ids.filter((id) => syncedMembers?.some((sm) => sm.memberId === id))
    );

    if (ids.length === 1) {
      setThisSyncADConfigs(
        syncedMembers?.find((sm) => sm.memberId === ids[0])?.settings ||
          defaultSyncADConfigs
      );
    } else {
      setThisSyncADConfigs(defaultSyncADConfigs);
    }

    const templatesCount = templates?.filter((t) =>
      t.assigneeIds.some((id) => ids.includes(id))
    ).length;
    if (templatesCount && templatesCount > 1) {
      setLimitedMappingAlertOpen(true);
    } else {
      setSyncADConfigsDialogOpen(true);
    }
  }

  function handleLimitedMappingConfirm() {
    setLimitedMappingAlertOpen(false);
    setSyncADConfigsDialogOpen(true);
  }

  function handleADSyncSettingsClose() {
    setIdsToChangeADSyncConfigs([]);
    setThisSyncADConfigs(defaultSyncADConfigs);
    setSyncADConfigsDialogOpen(false);
  }

  function getLinksToSyncWithAD(): LinkToSync[] | undefined {
    if (
      idsToChangeADSyncConfigs.length === 0 ||
      !templateLinks ||
      templates?.filter((t) =>
        t.assigneeIds.some((id) => idsToChangeADSyncConfigs.includes(id))
      ).length !== 1
    )
      return undefined;

    return templateLinks.filter(
      (tl) =>
        tl.isUnique &&
        tl.templateId ===
          templates?.find((t) =>
            t.assigneeIds.includes(idsToChangeADSyncConfigs[0])
          )?.id
    );
  }

  function handleADSyncSettingsSave(data: SyncADConfigsFormData): void {
    setThisSyncADConfigs(data);

    if (idsToChangeADSyncConfigs.length > 1) {
      setOverwriteConfirmOpen(true);
    } else {
      saveADSyncSettings(data);
    }
  }

  const [updateSyncedMembers, { isLoading: isUpdateSyncedMembersLoading }] =
    useUpdateSyncedMembersMutation();

  function handleOverwriteConfirm() {
    setOverwriteConfirmOpen(false);
    saveADSyncSettings(thisSyncADConfigs);
  }

  function handleOverwriteCancel() {
    setOverwriteConfirmOpen(false);
    handleADSyncSettingsClose();
  }

  async function saveADSyncSettings(
    settings: SyncADConfigsFormData | undefined = thisSyncADConfigs
  ): Promise<void> {
    const integrationId = activeDirectoryIntegration?.id;

    if (!integrationId || idsToChangeADSyncConfigs.length === 0) return;

    try {
      const {
        success,
        message,
        data: updatedSyncedMembers,
      } = await updateSyncedMembers({
        integrationId,
        memberIds: idsToChangeADSyncConfigs,
        settings,
      }).unwrap();
      if (success) {
        dispatch(editSyncedMembers(updatedSyncedMembers));
        handleADSyncSettingsClose();
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  if (
    isMembersLoading ||
    isReadTemplatesPending ||
    isIntegrationsLoading ||
    memberHashTags === undefined
  ) {
    return <Spinner fullScreen />;
  }

  return (
    <div className="relative flex h-full flex-col overflow-hidden">
      <div className="flex flex-col items-center justify-between gap-4 p-8 pb-0 lg:flex-row xl:gap-8">
        <div className="flex items-center gap-2 text-3xl font-medium text-gray-900 lg:text-2xl xl:text-3xl">
          <h2 className="font-medium">Members</h2>
          {!!members?.length && (
            <span className="font-semibold text-primary">
              ({members.length})
            </span>
          )}
          {filteredMembers.length !== members?.length && (
            <span className="text-lg text-gray-300">
              &ndash; Showing {filteredMembers.length} members.
            </span>
          )}
        </div>
        <div className="flex items-center gap-2 md:gap-4 lg:gap-2 xl:gap-4">
          <MembersListControls
            onViewChange={setView}
            onSearch={setSearchKeyword}
          ></MembersListControls>
          <DropdownX name="Add Members">
            <DropdownItemX
              icon={<FontAwesomeIcon icon={faEnvelope} />}
              label="Add by Email"
              description="Add members via email address"
              onClick={() =>
                navigateTo(
                  "/members/new" +
                    getAddMembersByHashTag(AddMembersBySections.EMAILS)
                )
              }
            />
            <DropdownItemX
              icon={<FontAwesomeIcon icon={faFileCsv} />}
              label="Import via CSV"
              description="Add members via CSV file"
              onClick={() =>
                navigateTo(
                  "/members/new" +
                    getAddMembersByHashTag(AddMembersBySections.CSV)
                )
              }
            />
            {activeDirectoryPlatform && (
              <DropdownItemX
                icon={<AzureLogoGray className="h-4 w-4" />}
                label="Sync with Microsoft AD"
                description="Sync members from Microsoft Active Directory"
                onClick={() =>
                  activeDirectoryIntegration
                    ? navigateTo(
                        "/members/new" +
                          getAddMembersByHashTag(
                            AddMembersBySections.ACTIVE_DIRECTORY
                          )
                      )
                    : openOAuthPopup()
                }
              />
            )}
          </DropdownX>
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        {!!filteredMembers.length && (
          <>
            {view === ViewAs.GRID && (
              <>
                <div className="px-8">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        classes={{
                          colorPrimary: "!text-gray-500",
                          checked: "!text-gray-900",
                        }}
                      />
                    }
                    label={
                      <span className="font-sans text-gray-500">
                        Select All
                      </span>
                    }
                  />
                </div>
                <div className="grid grid-cols-1 gap-8 px-8 pb-16 md:grid-cols-2 xl:grid-cols-3">
                  {filteredMembers.map((aMember: Member) => (
                    <MemberCard
                      key={aMember.id}
                      member={{
                        ...aMember,
                        isSyncedWithAD: syncedMembers?.some(
                          (sm) => sm.memberId === aMember.id
                        ),
                      }}
                      template={getTemplateByMemberId(aMember.id)}
                      actions={[
                        ...membersChildren,
                        ...divider,
                        ...imagesChildren,
                        ...divider,
                        ...(activeDirectoryIntegration
                          ? [...integrationsChildren, ...divider]
                          : []),
                        ...membersChildrenMore,
                      ]}
                      selected={selectedOnes.some((s) => s === aMember.id)}
                      onSelectedChange={handleSelectedChange}
                    />
                  ))}
                </div>
              </>
            )}
            {view === ViewAs.LIST && (
              <div className="h-full px-8 pt-8">
                <MembersListView
                  members={filteredMembers.map((aMember) => ({
                    ...aMember,
                    isSyncedWithAD: syncedMembers?.some(
                      (sm) => sm.memberId === aMember.id
                    ),
                  }))}
                  templates={templates}
                  actions={[
                    ...membersChildren,
                    ...divider,
                    ...imagesChildren,
                    ...divider,
                    ...(activeDirectoryIntegration
                      ? [...integrationsChildren, ...divider]
                      : []),
                    ...membersChildrenMore,
                  ]}
                  selectedMembers={selectedOnes}
                  onSelectionChange={(
                    rowSelectionModel: GridRowSelectionModel
                  ) => setSelectedOnes(rowSelectionModel)}
                />
              </div>
            )}
          </>
        )}
      </div>
      {selectedOnes.length > 0 && (
        <>
          <SelectionBar
            selectedMembers={getSelectedMembers(
              filteredMembers,
              selectedOnes.map((s) => Number(s))
            )}
            actions={getActionsOnSelected()}
            onClose={(e) => handleSelectAllChange(e, false)}
            className="fixed inset-x-0 bottom-0 md:inset-x-2 lg:absolute lg:inset-x-8"
          />
          <DialogX
            open={addLinkDialogOpen}
            onClose={() => setAddLinkDialogOpen(false)}
            fullScreen
            className="!max-w-5xl"
          >
            {!linkTypeToAdd && (
              <SelectLinkType
                onSelect={setLinkTypeToAdd}
                className="h-[37rem]"
              />
            )}
            {!!linkTypeToAdd && (
              <AddMemberLink
                linkType={linkTypeToAdd}
                onBack={() => setLinkTypeToAdd(undefined)}
                onAdd={addToLinks}
                onCancel={() => setAddLinkDialogOpen(false)}
              />
            )}
          </DialogX>
          <ConfirmationDialog
            title="Clear HashTags?"
            content="Are you sure you want to clear the hashtag for all the selected members?"
            confirmButtonIcon={
              <FontAwesomeIcon
                icon={isDeleteMemberHashTagsPending ? faSpinner : faBroom}
                className="!text-sm"
              />
            }
            confirmButtonText="Clear HashTag"
            onConfirm={handleClearMemberHashTagsConfirm}
            onCancel={() => setClearMemberHashTagsDialogShow(false)}
            open={clearMemberHashTagsDialogShow}
            danger
          />
          <DialogX
            open={addMemberHashTagsDialogShow}
            onClose={handleAddMemberHashTagsDialogClose}
            fullScreen
            className="!max-w-5xl"
          >
            {userIdsToAddHashTags?.length && (
              <SetHashTag
                memberIds={userIdsToAddHashTags}
                onAdd={handleAddMemberHashTagsDialogClose}
              />
            )}
          </DialogX>
          {isDeleteMemberHashTagsPending && <Spinner className="bg-white/50" />}
        </>
      )}
      <AlertDialog
        title="Trying to delete the Business Owner!"
        content={
          idsToDelete?.length > 1
            ? "The members you selected to delete includes the Owner of this account."
            : "You cannot delete the Owner of this account."
        }
        onConfirm={() => setDeleteOwnerAlertShow(false)}
        show={deleteOwnerAlertShow}
        danger
      />
      <AlertDialog
        title="Trying to delete the Business Owner/Admin!"
        content={
          idsToDelete?.length > 1
            ? "The members you selected to delete includes the Owner or one of the Admins of this account."
            : "You cannot delete the Owner or one of the Admins of this account."
        }
        onConfirm={() => setDeleteAdminAlertShow(false)}
        show={deleteAdminAlertShow}
        danger
      />
      <ConfirmationDialog
        title="Confirm Deletion"
        content={
          idsToDelete?.length > 1
            ? "Are you sure you want to delete the selected members?"
            : "Are you sure you want to delete this member?"
        }
        confirmButtonIcon={
          <FontAwesomeIcon
            icon={isDeleteMembersPending ? faSpinner : faUserSlash}
            className="!text-sm"
          />
        }
        confirmButtonText={
          idsToDelete?.length > 1 ? "Delete Members" : "Delete Member"
        }
        onConfirm={handleDeleteMembersConfirm}
        onCancel={handleDeleteMembersCancel}
        open={deleteMembersDialogShow}
        danger
      />
      <DialogX
        open={imageCropperDialogOpen}
        onClose={handleImageCropperCancel}
        fullScreen
        className="h-5/6 !max-w-5xl"
      >
        {membersUpdateImage?.file && (
          <ImageCropper
            src={membersUpdateImage.file}
            aspect={getAspectRatio(membersUpdateImage.type)}
            cropShape={getCropShape(membersUpdateImage.type)}
            maxWidth={AppConfigs.getMaximumWidth(membersUpdateImage.type)}
            onCrop={handleCroppedImage}
            onCancel={handleImageCropperCancel}
          />
        )}
        {(isSetProfilePictureLoading ||
          isSetCompanyLogoLoading ||
          isSetProfileBannerLoading) && <Spinner className="bg-white/50" />}
      </DialogX>
      <AlertDialog
        title="Limited Mapping for Selected Members"
        content={`The members do not use the same template, so individual 
          buttons are not available for selection in the AD configuration. If 
          you also want to configure individual buttons, please only select 
          members that use the same template.`}
        onConfirm={handleLimitedMappingConfirm}
        show={limitedMappingAlertOpen}
      />
      <SyncADConfigsDialog
        links={getLinksToSyncWithAD()}
        data={thisSyncADConfigs}
        onSave={handleADSyncSettingsSave}
        open={syncADConfigsDialogOpen}
        onClose={handleADSyncSettingsClose}
        loading={isUpdateSyncedMembersLoading}
      />
      <ConfirmationDialog
        title="Overwrite for All Selected Members"
        content={`The selected AD configuration will overwrite the previous one 
          for all selected members. This step cannot be undone. Are you sure 
          you want to proceed?`}
        confirmButtonText="Yes"
        onConfirm={handleOverwriteConfirm}
        cancelButtonText="No"
        onCancel={handleOverwriteCancel}
        open={overwriteConfirmOpen}
      />
      {isIntegrationLoading && <Spinner className="bg-white/50" />}
    </div>
  );
}

export default Members;
